<template>
<div>
  <!-- Preview -->
  <div v-if="!recorded">
    <div class="mt-2 mb-2">
      Record yourself asking your question.<br/>
      <b-form-textarea class="mt-2" placeholder="I was wondering..." v-model="questionInput"/>
    </div>
    <preview-gum
      class="mt-3 shadow-lg p-2 mb-5 bg-white rounded"
      ref="preview"
      @on-stream="(s) => stream = s"/>
    <stream-recorder
      :stream="stream"
      @recording-change="(r) => { if (!r) recorded = true }"
      :config="{disableEdit:true, wsIp:$app.streamingServerIp, hlsBucket}"/>
  </div>
  <!-- Review -->
  <div v-else>
    <div class="mt-2 mb-2">
      Edit your question.<br/>
      <b-form-textarea class="mt-2" placeholder="I was wondering..." v-model="questionInput"/>
    </div>
    Preview
    <player :url="getRecordingURL()"/>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <button class="btn btn-primary" @click="recorded = false">Redo</button>
      <button class="btn btn-primary" @click="post()">Post</button>
    </div>
  </div>
</div>
</template>

<script>
import previewGum from '../components/previewGum.vue'
import StreamRecorder from '../components/streamRecorder.vue'
import { addQuestionRecording } from "@/services/recordings"
import { randomString } from '../services/utils'

export default {
  components: { 
    previewGum,
    StreamRecorder,
    Player: () => import('@/views/tests/TestHLS.vue'),
  },
  props: {
    event: Object,
    eventUser: Object,
  },
  data() {
    return {
      stream: null,
      questionInput: null,
      recorded: false,
      randomId: randomString(32),
    };
  },
  computed: {
    hlsBucket() {
      return `${this.event.id}/questions/${this.eventUser.id}question${this.randomId}`;
    }
  },
  mounted() {
    this.questionInput = this.eventUser.question;
  },
  methods: {
    getRecordingURL() {
      return `https://storage.googleapis.com/hh-streams/${this.hlsBucket}/vod.m3u8`;
    },
    post() {
      addQuestionRecording(this.event.id, {
        author: this.eventUser.name,
        userId: this.eventUser.id,
        question: this.questionInput,
        questionRecording: this.getRecordingURL(),
      });
      this.$parent.eventUserBehavior.on('questionRecordingComplete');
    }
  }
}
</script>

<style>

</style>